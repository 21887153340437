import React, { useEffect, useState } from 'react';
import appConfig from '../app-config.json';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import Moment from 'react-moment';
import 'moment-timezone';
import moment from 'moment-timezone';
//import Item from '@mui/material/Item'

interface StevneData {
    id: string;
    title: string;
    facilityName: string;
    organizationName: string;
    startDate: string;
    endDate: Date;
    status: number;
    applicationType: number;
    competitionTypeName: string;
    registrationStartDate: Date;
    registrationEndDate: Date;
    hasResult: boolean;
    resultFileUrl: string;
    applicableForClassification: boolean;
    hasPendingChangeRequests: boolean;
    startDateUTC: Date;
}
/*
function Stevne(props: StevneData, index: number) {

    return (
        <Paper
            sx={{
                p: 2,
                mx: 'auto',
                my: 2,
                maxWidth: 800,
                flexGrow: 1
            }}
        >
            <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                    <Typography variant="subtitle1">
                        <Moment date={new Date(Date.parse(props.startDate + 'Z'))} format="DD.MM.YYYY [kl.] HH:mm" tz="Europe/Oslo" />
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <Typography gutterBottom variant="subtitle1" component="div">
                        {props.title}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                        {props.facilityName}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {props.competitionTypeName}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {props.organizationName}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography sx={{ cursor: 'pointer' }} variant="body2">
                        Remove
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    );
}
*/

function Terminliste() {
    const [error, setError] = useState(null);
    const [isLoadedNFS, setIsLoadedNFS] = useState(false);
    const [itemsNFS, setItemsNFS] = useState([]);
    const [isLoadedSK, setIsLoadedSK] = useState(false);
    const [itemsSK, setItemsSK] = useState([]);


    // Note: the empty deps array [] means
    // this useEffect will run once
    // similar to componentDidMount()
    useEffect(() => {
        var todayUTC: Date = moment.utc().local().startOf('day').toDate();
        
        // console.log(todayUTC.toISOString());
        fetch(appConfig.APIURL + 'query/competitionlist?pageIndex=0&pageSize=100&orderBy=startDate:asc&startDate=ge:'+todayUTC.toISOString()+'&branches=in:[%220BEFF7E2-94F7-4284-B76E-FC3E17B29E4F%22]&organizationId=in:[%2295b03ebb-9fba-4d34-44b4-08d87a883fce%22]')
            .then(response => response.json())
            .then(
                (result) => {
                    setIsLoadedNFS(true);
                    setItemsNFS(result.items);
                    // console.log(result.items);
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    setIsLoadedNFS(true);
                    setError(error);
                    console.error(error);
                }
            );
            fetch('./static/svartkruttstevner.json')
            //fetch('https://gist.github.com/oivindmo/059e9dce306280fcafc873e33ceb12da')
            .then(response => response.json())
            .then(
                (result) => {
                    setIsLoadedSK(true);
                    setItemsSK(result.items.filter((a: { startDate: string; }) => new Date(Date.parse(a.startDate + 'Z')) >= todayUTC ));
                    // console.log(result.items);
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    setIsLoadedSK(true);
                    setError(error);
                    console.error(error);
                }
            );
    }, [])

    

    if (error) {
        return <div>Error</div>;
    } else if (!isLoadedNFS || !isLoadedSK) {
        return <div>Loading...</div>;
    } else {

        var allitems: StevneData[] = [...itemsNFS, ...itemsSK]
        allitems.sort((a, b) => Date.parse(a.startDate + 'Z') - Date.parse(b.startDate + 'Z') );

        return (

            // <Container fixed>
            //     {items.map((item: StevneData, index: number) => (
            //         <Stevne {...item} />
            //     ))}
            // </Container>
            <TableContainer
                component={Paper}
                sx={{
                    maxWidth: 1000,
                    mx: 'auto'
                }}
            >
                <Table size="small" aria-label="Terminliste" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>Dato</TableCell>
                            <TableCell>Stevne</TableCell>
                            <TableCell>Sted</TableCell>
                            <TableCell>Stevnetype</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {allitems.map((item: StevneData, index: number) => (
                            <TableRow
                                key={item.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row" style={{ textDecoration : item.status === 3 ? 'line-through' : 'none' }}>
                                    <Moment date={new Date(Date.parse(item.startDate + 'Z'))} format="DD.MM.YYYY HH:mm" tz="Europe/Oslo" />
                                </TableCell>
                                <TableCell>
                                    <Box style={{ textDecoration: 'none'}} component="div" sx={{ display: item.status === 3 ? 'inline' : 'none', color: 'error.main' }}>AVLYST </Box>
                                    <Box style={{ textDecoration : item.status === 3 ? 'line-through' : 'none' }} component="div" sx={{ display: 'inline' }}>
                                        {item.title}
                                    </Box>
                                </TableCell>
                                <TableCell style={{ textDecoration : item.status === 3 ? 'line-through' : 'none' }}>{item.facilityName}</TableCell>
                                <TableCell style={{ textDecoration : item.status === 3 ? 'line-through' : 'none' }}>{item.competitionTypeName}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }
}

export default Terminliste;