import React from 'react';
import './App.css';
import Terminliste from './components/terminliste.component';
import Typography from '@mui/material/Typography';

function App() {
  return (
    <div className="App">
      <header className="App-header">
      <Typography variant="h4">
        Terminliste TPK
      </Typography>
      </header>
      <Terminliste />
    </div>
  );
}

// function Terminliste() {
//   const [error, setError] = useState(null);
//   const [isLoaded, setIsLoaded] = useState(false);
//   const [items, setItems] = useState([]);

//   interface Stevne {
//     id: string;
//     title: string;
//   }

//   // Note: the empty deps array [] means
//   // this useEffect will run once
//   // similar to componentDidMount()
//   useEffect(() => {
//     fetch(appConfig.APIURL + 'query/competitionlist?pageIndex=0&pageSize=10&orderBy=startDate:asc&startDate=ge:2023-02-06T23:00:00.000Z&branches=in:[%220BEFF7E2-94F7-4284-B76E-FC3E17B29E4F%22]&organizationId=in:[%2295b03ebb-9fba-4d34-44b4-08d87a883fce%22]')
//       .then(response => response.json())
//       .then(
//         (result) => {
//           setIsLoaded(true);
//           setItems(result.items);
//           console.log(result.items);
//         },
//         // Note: it's important to handle errors here
//         // instead of a catch() block so that we don't swallow
//         // exceptions from actual bugs in components.
//         (error) => {
//           setIsLoaded(true);
//           setError(error);
//         }
//       )
//   }, [])

//   if (error) {
//     return <div>Error</div>;
//   } else if (!isLoaded) {
//     return <div>Loading...</div>;
//   } else {
//     return (
//       <ul>
//         {items.map((item: Stevne) => (
//           <li key={item.id}>
//             {item.title}
//           </li>
//         ))}
//       </ul>
//     );
//   }
// }

export default App;
